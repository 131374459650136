.section {
    padding: 10px;
    margin-bottom: 17px;
}

.bullet {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: -13px;
    padding: 10px;
    transition: box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition for box-shadow and border */
    border: 1px solid transparent; /* Initial transparent border to maintain consistent layout */
    border-radius: 10px; /* Rounded corners */
}

.bullet:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
    border-radius: 1px;
    border-color: #000;
    border-radius: 10px; /* Rounded corners */
}

.bullet img {
    width: 115px;
    height: 115px;
    margin-right: 20px;
}

.bullet div {
    flex: 1; /* Ensures the <div> takes up remaining space */
}

.bullet p {
    margin: 0;
    text-align: left;
}
.bullet .title {
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: none; /* Remove underline */
    color: "inherit"; /* Inherit the color from the parent element */
}

.bullet .title a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the color from the parent element */
    position: relative;
}
.bullet .title a:hover {
    color: #581F73
}

.bullet .title a::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; /* Adjust this value to place the underline */
    width: 0;
    height: 2px; /* Adjust this value to change the thickness of the underline */
    background-color: #581F73;
    transition: width 0.3s ease-in-out;
}

.bullet .title a:hover::after {
    width: 100%;
}
.bullet .title-sub {
    font-size: 1.4rem;
}
.bullet .description {
    font-size: 1.25rem;
}
.bullet .link-class {
    margin-top: 10px; /* Space above the links */
}
.bullet .link {
    text-decoration: underline;
    font-size: 1.15rem;
    font-weight: 300;
    color: inherit;
    position: relative;
}
.bullet .link-divider {
    font-size: 1.15rem;
}
.bullet .link:hover {
    font-weight: 600; /* Make the font bold on hover */
}



@media screen and (max-width: 500px) {
    .bullet .title {
        font-size: 1.15rem;

    }
    .bullet .title-sub {
        font-size: 1.15rem;

    }
    .bullet .description {
        font-size: 1rem;
    }
    .divider-sub {
        font-size: 1.3rem;
    }
    .bullet {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: -13px;
        padding: 10px;
        transition: box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition for box-shadow and border */
        border: 1px solid transparent; /* Initial transparent border to maintain consistent layout */
        border-radius: 10px; /* Rounded corners */
    }

    .bullet .link {
        text-decoration: underline;
        font-size: 1rem;
        font-weight: 300;
        color: inherit;
        position: relative;
    }
    .bullet .link-divider {
        font-size: 1rem;
    }

}
    
@media screen and (max-width: 350px) {
    .bullet {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: -25px;
        margin-right: -25px;
        margin-bottom: -13px;
        padding: 10px;
        transition: box-shadow 0.3s ease, border 0.3s ease; /* Smooth transition for box-shadow and border */
        border: 1px solid transparent; /* Initial transparent border to maintain consistent layout */
        border-radius: 10px; /* Rounded corners */
    }

}