.about {
    padding-left: 20px;
    margin-top: -20px;
    margin-bottom: 15px;
}

.about-content {
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.about p {
    margin-left: 30px;
    margin-right: 30px;
}

.profile-image {
    width: 22%;
    height: auto;
    margin-right: 20px; 
    margin-left: 30px;
    margin-top: 20px;
}

.text-container {
    flex: 1; 
    margin-top: 20px;
    font-size: 1.25rem; /* Default font size */
}

@media (max-width: 768px) { 
    .about-content {
        flex-direction: column; 
        align-items: center; 
    }

    .profile-image {
        margin-right: 0;
        margin-left: 0;
        margin-bottom: 20px; 
        width: 40%;
    }
    .text-container {
        flex: 1; 
        margin-top: -5px;
    }
}

.hyperlink {
    text-decoration: none;
    color: #581F73;
    font-weight: 600;
    position: relative; 
}

.hyperlink::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -2px; 
    width: 0;
    height: 2px; 
    background-color: #581F73;
    transition: width 0.3s ease-in-out;
}

.hyperlink:hover::after {
    width: 100%;
}

@media screen and (max-width: 500px) {
    .text-container {
        font-size: 1rem; /* Smaller font size for screens 500px or less */
    }

    .text-container p {
        margin: 0.5rem 0; /* Optional: adjust margins to fit smaller screens */
    }
}
