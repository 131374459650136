header {
    padding: 20px;
    
}
.header-container {
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 40px;
    padding-right: 40px;

}

/* fade */
.fade-in-section {
    opacity: 0;
    transform: translateY(75px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}
  
.fade-in-visible {
    opacity: 1;
    transform: translateY(0);
}
  
h1 {
    margin: 0;
    font-size: 4rem;
    text-align: center; /* Center text within the h1 element */
}



.contact-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 5px;
}

.contact-icons a {
    color: inherit;
    text-decoration: none;
}

@media screen and (max-width: 500px) {
    h1 {
        font-size: 3.5rem;
    }

}

@media screen and (max-width: 350px) {
    h1 {
        font-size: 3rem;
    }

}