/* Global styles */
body {
  font-family: 'Times New Roman', Times, serif;
  margin: 0;
  padding: 0;
}

/* fade */
.fade-in-section {
  opacity: 0;
  transform: translateY(75px); /* Increased translate distance for more dramatic effect */
  transition: opacity 1s ease-out, transform 1s ease-out; /* Longer duration for more noticeable effect */
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0);
}

/* Light mode styles */
.light-mode {
  background-color: #f2d8ff;
  color: #000000;
}

.light-mode .App {
  background-color: #f2d8ff;
}

.light-mode .header-logo {
  filter: invert(0);
}

.light-mode .hyperlink {
  color: #581F73;
}
.light-mode .bullet .link {
  color: #581F73;
}

.light-mode .hyperlink::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #581F73;
  transition: width 0.3s ease-in-out;
}

.light-mode .hyperlink:hover::after {
  width: 100%;
}

.light-mode .bullet .title a:hover {
  color: #581F73;
}

.light-mode .bullet .title a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #581F73;
  transition: width 0.3s ease-in-out;
}

.light-mode .bullet .title a:hover::after {
  width: 100%;
}

.light-mode .bullet:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 1px;
  border-color: #000;
  border-radius: 10px;
}

.light-mode .divider {
  background-color: #000;
}

/* Dark mode styles */
.dark-mode {
  background-color: #581F73;
  color: #ffffff;
}

.dark-mode .App {
  background-color: #581F73;
}

.dark-mode .hyperlink {
  color: #f2d8ff;
}

.dark-mode .bullet .link {
  color: #f2d8ff;
}

.dark-mode .hyperlink::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #f2d8ff;
  transition: width 0.3s ease-in-out;
}

.dark-mode .hyperlink:hover::after {
  width: 100%;
}

.dark-mode .bullet .title a:hover {
  color: #f2d8ff;
}

.dark-mode .bullet .title a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #f2d8ff;
  transition: width 0.3s ease-in-out;
}

.dark-mode .bullet .title a:hover::after {
  width: 100%;
}

.dark-mode .bullet:hover {
  box-shadow: 0 8px 16px rgba(255, 255, 255, 0.1);
  border-radius: 1px;
  border-color: #ffffff;
  border-radius: 10px;
}

.dark-mode .divider {
  background-color: #ffffff;
}

/* Shared styles */
.header-logo {
  width: 80px;
  height: auto;
  justify-content: flex-start;
  margin-left: -20px;
  margin-top: -5px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.toggle-button {
  background-color: #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #bbb;
}

main {
  padding: 20px;
  margin-left: 5%;
  margin-right: 5%;
}

/* Material-UI Button Styles */
.MuiButton-root {
  margin-left: auto;
  background-color: #ccc;
  color: #581F73;
}

body, .MuiTypography-root {
  font-family: 'Times New Roman', Times, serif !important;
}

.MuiButton-root:hover {
  background-color: #bbb;
}
